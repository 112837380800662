// src/utils/updateDocumentTitle.js
export const updateDocumentTitle = (subtitle) => {
  // For the homepage
  if (subtitle === "world's most advanced Downloader") {
    document.title = `yLoader - ${subtitle}`;
    return;
  }
  
  // For service-specific downloader pages
  if (subtitle && subtitle.includes('Downloader')) {
    const serviceName = subtitle.split('world')[1]
                              .split('Downloader')[0]
                              .replace("'s most advanced ", "")
                              .trim();
    document.title = `${serviceName} Downloader - yLoader`;
    return;
  }
  
  // For other pages (Browser Extension, etc.)
  document.title = subtitle ? `yLoader - ${subtitle}` : 'yLoader';
};