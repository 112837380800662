import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { IoMdArrowRoundDown } from "react-icons/io";
import { RiChromeFill } from "react-icons/ri";
import { FaWandMagicSparkles, FaImage, FaCaretDown } from "react-icons/fa6";
import { FaInfoCircle } from "react-icons/fa";
import { RxHamburgerMenu } from 'react-icons/rx';

function Header({ toggleSidebar }) {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      if (window.innerWidth > 768) {
        setMobileNavOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <header className="header">
      <div className="header-left">
        <button 
          className="hamburger-button"
          onClick={toggleSidebar}
        >
          <RxHamburgerMenu size={24} />
        </button>
        <NavLink to="/" className="header-logo-link">
          <div className="header-left">
            <img src="/logo192.png" alt="yLoader" className="app-icon" />
            <h1>yLoader</h1>
          </div>
        </NavLink>
      </div>

      {/* Desktop Navigation */}
      <nav className="header-nav">
        <NavLink to="/" className="nav-item">
          <IoMdArrowRoundDown className="nav-icon" />
          Download
        </NavLink>
        <NavLink to="/browser-extension" className={({isActive}) => 
          `nav-item ${isActive ? 'active' : ''}`
        }>
          <RiChromeFill className="nav-icon" />
          Browser Extension
        </NavLink>
        <NavLink to="/features" className="nav-item">
          <FaWandMagicSparkles className="nav-icon" />
          Features
        </NavLink>
        <NavLink to="/images" className="nav-item">
          <FaImage className="nav-icon" />
          Images
        </NavLink>
        <NavLink to="/about" className="nav-item">
          <FaInfoCircle className="nav-icon" />
          About
        </NavLink>
      </nav>

      {/* Mobile Navigation */}
      {isMobile && (
        <>
          <button 
            className={`mobile-nav-toggle ${mobileNavOpen ? 'open' : ''}`}
            onClick={() => setMobileNavOpen(!mobileNavOpen)}
          >
            <FaCaretDown size={24} />
          </button>

          <nav className={`mobile-nav ${mobileNavOpen ? 'active' : ''}`}>
            <NavLink to="/" className="nav-item" onClick={() => setMobileNavOpen(false)}>
              <IoMdArrowRoundDown className="nav-icon" />
              Download
            </NavLink>
            <NavLink to="/browser-extension" className={({isActive}) => 
              `nav-item ${isActive ? 'active' : ''}`
            } onClick={() => setMobileNavOpen(false)}>
              <RiChromeFill className="nav-icon" />
              Browser Extension
            </NavLink>
            <NavLink to="/features" className="nav-item" onClick={() => setMobileNavOpen(false)}>
              <FaWandMagicSparkles className="nav-icon" />
              Features
            </NavLink>
            <NavLink to="/images" className="nav-item" onClick={() => setMobileNavOpen(false)}>
              <FaImage className="nav-icon" />
              Images
            </NavLink>
            <NavLink to="/about" className="nav-item" onClick={() => setMobileNavOpen(false)}>
              <FaInfoCircle className="nav-icon" />
              About
            </NavLink>
          </nav>
        </>
      )}
    </header>
  );
}

export default Header;