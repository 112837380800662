import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import DownloadButton from './components/DownloadButton';
import Layout from './components/Layout';
import BrowserExtension from './pages/BrowserExtension';
import Downloader from './pages/Downloader';
import { updateDocumentTitle } from './utils/updateDocumentTitle';
import SEO from './components/SEO';

function HomePage() {
  const subtitle = "world's most advanced Downloader";

  useEffect(() => {
    updateDocumentTitle(subtitle);
    return () => updateDocumentTitle();
  }, [subtitle]);

  return (
    <>
      <SEO 
        title="yLoader - World's Most Advanced Video & Audio Downloader"
        description="Download videos and audio from YouTube, TikTok, Instagram and more. High-quality downloads, fast conversion, and completely free to use."
        keywords="video downloader, youtube downloader, tiktok downloader, instagram downloader, social media downloader"
        canonical="/"
      />
      <h1 className="main-title">yLoader</h1>
      <p className="main-subtitle">{subtitle}</p>
      <DownloadButton />
    </>
  );
}

const services = ['youtube', 'tiktok', 'instagram', 'x', 'facebook', 'reddit', 'soundcloud', 'dailymotion'];

function App() {
  return (
    <Router>
      <Routes>
        {/* Homepage Route */}
        <Route path="/" element={
          <Layout>
            <HomePage />
          </Layout>
        } />
        
        {/* Browser Extension Route */}
        <Route path="/browser-extension" element={
          <Layout>
            <BrowserExtension />
          </Layout>
        } />

        {/* Dynamic Service Routes */}
        {services.map(service => (
          <Route 
            key={service}
            path={`/${service}-downloader`}
            element={
              <Layout>
                <Downloader service={service} />
              </Layout>
            }
          />
        ))}
      </Routes>
    </Router>
  );
}

export default App;