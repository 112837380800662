// src/components/Sidebar.js
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { FaHome, FaCaretDown } from 'react-icons/fa';
import { RiChromeFill } from 'react-icons/ri';

const services = [
  { name: 'YouTube', id: 'youtube' },
  { name: 'TikTok', id: 'tiktok' },
  { name: 'Instagram', id: 'instagram' },
  { name: 'X', id: 'x' },
  { name: 'Facebook', id: 'facebook' },
  { name: 'Reddit', id: 'reddit' },
  { name: 'SoundCloud', id: 'soundcloud' },
  { name: 'Dailymotion', id: 'dailymotion' }
];

function Sidebar({ isOpen, onClose }) {
  const [downloadersOpen, setDownloadersOpen] = useState(() => {
    const saved = localStorage.getItem('downloadersOpen');
    return saved !== null ? JSON.parse(saved) : true;
  });

  useEffect(() => {
    localStorage.setItem('downloadersOpen', JSON.stringify(downloadersOpen));
  }, [downloadersOpen]);

  const handleNavClick = () => {
    if (window.innerWidth <= 768) {
      onClose();
    }
  };

  return (
    <div className={`sidebar ${!isOpen ? 'closed' : ''}`}>
      <div className="sidebar-nav">
        <div className="sidebar-fixed">
          <NavLink 
            to="/" 
            className={({isActive}) => `sidebar-item ${isActive ? 'active' : ''}`}
            onClick={handleNavClick}
            end
          >
            <FaHome className="sidebar-icon" />
            Home
          </NavLink>
          <NavLink 
            to="/browser-extension" 
            className={({isActive}) => `sidebar-item ${isActive ? 'active' : ''}`}
            onClick={handleNavClick}
          >
            <RiChromeFill className="sidebar-icon" />
            Browser Extension
          </NavLink>
          <div className="sidebar-divider"></div>
        </div>
        
        <div className="sidebar-scroll">
          <div 
            className={`sidebar-item sidebar-accordion ${downloadersOpen ? 'open' : ''}`}
            onClick={() => setDownloadersOpen(!downloadersOpen)}
          >
            <div className="sidebar-accordion-header">
              <FaCaretDown className={`sidebar-icon ${downloadersOpen ? 'open' : ''}`} />
              Downloader
            </div>
          </div>
          <div className={`sidebar-accordion-content ${downloadersOpen ? 'open' : ''}`}>
            {services.map(service => (
              <NavLink 
                key={service.id}
                to={`/${service.id}-downloader`}
                className={({isActive}) => `sidebar-item ${isActive ? 'active' : ''}`}
                onClick={handleNavClick}
              >
                <img 
                  src={`/services/${service.id}_icon.svg`}
                  alt={`${service.name} icon`}
                  className="sidebar-service-icon"
                />
                {service.name} Downloader
              </NavLink>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;