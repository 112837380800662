// src/components/Layout.js
import React, { useState, useEffect } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';

function Layout({ children }) {
  const [sidebarOpen, setSidebarOpen] = useState(() => {
    const saved = localStorage.getItem('sidebarOpen');
    const isMobile = window.innerWidth <= 768;
    // Always false on mobile, use localStorage only on desktop
    return isMobile ? false : (saved !== null ? JSON.parse(saved) : true);
  });

  useEffect(() => {
    // Only save to localStorage on desktop
    if (window.innerWidth > 768) {
      localStorage.setItem('sidebarOpen', JSON.stringify(sidebarOpen));
    }
  }, [sidebarOpen]);

  // Add resize listener to handle desktop/mobile transitions
  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 768;
      if (isMobile) {
        setSidebarOpen(false);
      } else {
        // Restore desktop state from localStorage when transitioning back
        const saved = localStorage.getItem('sidebarOpen');
        setSidebarOpen(saved !== null ? JSON.parse(saved) : true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className="app">
      <Header toggleSidebar={toggleSidebar} />
      <Sidebar isOpen={sidebarOpen} onClose={() => setSidebarOpen(false)} />
      {/* Overlay for mobile */}
      <div 
        className={`sidebar-overlay ${sidebarOpen ? 'active' : ''}`}
        onClick={() => setSidebarOpen(false)}
      />
      <main className="main">
        {children}
      </main>
    </div>
  );
}

export default Layout;