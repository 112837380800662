import React, { useEffect } from 'react';
import { updateDocumentTitle } from '../utils/updateDocumentTitle';

function BrowserExtension() {
  useEffect(() => {
    updateDocumentTitle('Browser Extension');
    return () => updateDocumentTitle();
  }, []);

  return (
    <div>
      <h1>Browser Extension</h1>
      <p>Download our browser extension for quick and easy downloads.</p>
    </div>
  );
}

export default BrowserExtension;