import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { IoMdArrowRoundDown } from "react-icons/io";
import { FaWindows, FaApple } from 'react-icons/fa';

const DEFAULT_COLOR = {
  primary: '#3498db',
  hover: '#2980b9',
  active: '#2475a8'
};

const DOWNLOADS_ENABLED = false; // Toggle this to enable/disable downloads globally

function DownloadButton() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOS, setSelectedOS] = useState('windows');
  const [osRequirement, setOsRequirement] = useState('Requires Windows 10 or later');
  const location = useLocation();

  // Get service from URL (e.g., /youtube-downloader -> youtube)
  const service = location.pathname.split('-downloader')[0].split('/')[1];

  // Get button color based on service
  const getButtonColor = () => {
    if (!service) return DEFAULT_COLOR;

    const serviceColors = {
      youtube: { primary: '#FF0000', hover: '#D90000', active: '#B50000' },
      tiktok: { primary: '#000000', hover: '#1a1a1a', active: '#333333' },
      instagram: { primary: '#E4405F', hover: '#D81F4D', active: '#C81E4B' },
      x: { primary: '#000000', hover: '#1a1a1a', active: '#333333' },
      facebook: { primary: '#1877F2', hover: '#0E5FC1', active: '#0C54AB' },
      reddit: { primary: '#FF4500', hover: '#E03D00', active: '#CC3700' },
      soundcloud: { primary: '#FF3300', hover: '#E62E00', active: '#CC2900' },
      dailymotion: { primary: '#0066DC', hover: '#0052B3', active: '#004899' }
    };

    return serviceColors[service] || DEFAULT_COLOR;
  };

  const buttonColor = getButtonColor();

  useEffect(() => {
    const closeDropdown = (e) => {
      if (!e.target.closest('.download-section')) {
        setDropdownOpen(false);
      }
    };
    
    document.addEventListener('click', closeDropdown);
    return () => document.removeEventListener('click', closeDropdown);
  }, []);

  useEffect(() => {
    setOsRequirement(selectedOS === 'windows' 
      ? 'Requires Windows 10 or later'
      : 'Requires macOS 11 (Big Sur) or later'
    );
  }, [selectedOS]);

  return (
    <div className="download-section">
      <button 
        className={`download-button ${!DOWNLOADS_ENABLED ? 'disabled' : ''}`}
        style={{
          '--button-color': buttonColor.primary,
          '--button-hover-color': buttonColor.hover,
          '--button-active-color': buttonColor.active
        }}
      >
        <span>
          <IoMdArrowRoundDown className="nav-icon" />
          Download
        </span>
        <div className="separator"></div>
        <div 
          className={`os-selector ${dropdownOpen ? 'active' : ''}`} 
          onClick={(e) => DOWNLOADS_ENABLED && setDropdownOpen(!dropdownOpen)}
        >
          {selectedOS === 'windows' ? <FaWindows /> : <FaApple />}
        </div>
        {!DOWNLOADS_ENABLED && <div className="coming-soon-badge">Coming Soon</div>}
      </button>
      
      {DOWNLOADS_ENABLED && (
        <div className={`dropdown-menu ${dropdownOpen ? 'active' : ''}`}>
          <div className="dropdown-item" onClick={() => {
            setSelectedOS('windows');
            setDropdownOpen(false);
          }}>
            <FaWindows /> Windows
          </div>
          <div className="dropdown-item" onClick={() => {
            setSelectedOS('mac');
            setDropdownOpen(false);
          }}>
            <FaApple /> macOS
          </div>
        </div>
      )}
      
      {DOWNLOADS_ENABLED && <div className="os-requirement">{osRequirement}</div>}
    </div>
  );
}

export default DownloadButton;