// src/pages/Downloader.js
import React, { useEffect } from 'react';
import { updateDocumentTitle } from '../utils/updateDocumentTitle';
import DownloadButton from '../components/DownloadButton';
import SEO from '../components/SEO';

const services = {
  youtube: 'YouTube',
  tiktok: 'TikTok', 
  instagram: 'Instagram',
  x: 'X (Twitter)',
  facebook: 'Facebook',
  reddit: 'Reddit',
  soundcloud: 'SoundCloud',
  dailymotion: 'Dailymotion'
};

const serviceColors = {
  youtube: '#FF0000',
  tiktok: '#000000',
  instagram: '#E4405F',
  x: '#000000',
  facebook: '#1877F2',
  reddit: '#FF4500',
  soundcloud: '#FF3300',
  dailymotion: '#0066DC'
};

function Downloader({ service }) {
  const serviceName = services[service];
  const subtitle = `world's most advanced ${serviceName} Downloader`;
  
  const seoData = {
    youtube: {
      title: `Download YouTube Videos Free | yLoader YouTube Downloader`,
      description: `Download YouTube videos in HD, 4K and MP3. Fast, free and secure YouTube downloader. No registration required. Convert and download YouTube videos instantly.`,
      keywords: `youtube downloader, download youtube videos, youtube to mp3, youtube converter, hd youtube downloader`
    },
    tiktok: {
      title: `TikTok Video Downloader | Download TikTok Videos Without Watermark`,
      description: `Download TikTok videos without watermark in HD quality. Free TikTok video downloader - save TikTok videos to your device easily and quickly.`,
      keywords: `tiktok downloader, download tiktok video, tiktok video downloader no watermark, save tiktok videos`
    },
    instagram: {
      title: `Instagram Downloader | Save Instagram Photos, Videos & Reels`,
      description: `Download Instagram photos, videos, reels and stories in original quality. Save Instagram content offline with this free Instagram downloader.`,
      keywords: `instagram downloader, instagram video downloader, instagram photo downloader, download instagram reels, save instagram stories`
    },
    x: {
      title: `X (Twitter) Video Downloader | Download Twitter Videos Free`,
      description: `Download X (formerly Twitter) videos in high quality. Save tweets, videos and GIFs from X/Twitter to your device without watermark.`,
      keywords: `twitter video downloader, x video downloader, download twitter videos, save tweets, twitter downloader`
    },
    facebook: {
      title: `Facebook Video Downloader | Download Facebook Videos HD`,
      description: `Download Facebook videos and reels in HD quality. Save Facebook videos, stories and live streams to your device. Fast and free Facebook downloader.`,
      keywords: `facebook video downloader, download facebook videos, facebook reels downloader, fb video downloader, save facebook videos`
    },
    reddit: {
      title: `Reddit Video & GIF Downloader | Download Reddit Content`,
      description: `Download Reddit videos, GIFs and content in original quality. Save posts from any subreddit including audio. Free Reddit downloader tool.`,
      keywords: `reddit video downloader, download reddit videos, reddit gif downloader, save reddit posts, reddit content downloader`
    },
    soundcloud: {
      title: `SoundCloud Downloader | Download SoundCloud Songs MP3`,
      description: `Download SoundCloud songs and playlists in MP3 format. Save SoundCloud music in high quality. Free SoundCloud downloader and converter.`,
      keywords: `soundcloud downloader, download soundcloud, soundcloud to mp3, soundcloud music downloader, save soundcloud songs`
    },
    dailymotion: {
      title: `Dailymotion Video Downloader | Download Dailymotion HD`,
      description: `Download Dailymotion videos in HD quality. Save Dailymotion content in MP4 format. Fast and free Dailymotion video downloader tool.`,
      keywords: `dailymotion downloader, download dailymotion videos, dailymotion video converter, save dailymotion videos, dailymotion hd downloader`
    }
  };

  useEffect(() => {
    updateDocumentTitle(subtitle);
    return () => updateDocumentTitle();
  }, [subtitle]);

  return (
    <>
      <SEO 
        title={seoData[service].title}
        description={seoData[service].description}
        keywords={seoData[service].keywords}
        canonical={`/${service}-downloader`}
        ogImage={`/images/${service}-og.jpg`}
      />
      <h1 className="main-title">
        <span className={`service-letter ${service === 'tiktok' ? 'tiktok-glitch' : ''}`} 
              style={{ color: service === 'tiktok' ? '#fff' : serviceColors[service] }}>
          y
        </span>
        Loader
      </h1>
      <p className="main-subtitle">{subtitle}</p>
      <DownloadButton />
    </>
  );
}

export default Downloader;